import { Suspense, useEffect } from "react";
import AppHeader from './header.tsx';
import { Outlet, useLocation } from 'react-router-dom';
import Config from "../config.ts";


export default function Frame() {

    const location = useLocation();
    useEffect(()=>{
        document.title = `${location.pathname} - ${Config.appName}`;
    }, [location]);

    return (
        <>
            <AppHeader />
            <div style={{ padding: 10 }}>
                <Suspense fallback={<div />}>
                    <Outlet />
                </Suspense>
            </div>
        </>
    );
}