import Frame from './frame';
import Index  from './Home';
import API from '../API';
import ErrorPage from './errors';
import Debug from './debug';
import Data from './data';
// import { Index } from './App';

export default [{
    path: '/',
    id: 'root',
    element: <Frame />,
    errorElement: <ErrorPage />,
    loader: async ()=> {
        return {
            session: await API.session.check().catch(() => null)
        };
    },
    children: [
        Index,
        Debug,
        Data
    ]
}]