import React from 'react';

const Element = React.lazy(() => import('./add.component'));

export default {
    index: true,
    path:"Add",
    title: "add",
    element: <Element />
};
